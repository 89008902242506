import React from 'react';
import {Button, Grid, Typography} from '@mui/material';
import {store} from '../../store/store';
import {navigateTo} from '../navigation/navigationSlice';
import {NavigationPath} from '../navigation/navigationPath';
import {selectMessage, selectProfileIds} from './fromRegistrationResultSlice';
import {useSelector} from 'react-redux';
import {Strings} from "../../assets/Strings";
import {purple} from "@mui/material/colors";
import {verifyToken} from "../validator/functions";

export default function FormRegistrationResult() {
    const registrationResultMessage = useSelector(selectMessage)
    const profileIds = useSelector(selectProfileIds)
    if (!profileIds || profileIds.email === null || profileIds.uri === null) {
        return (
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: purple[800],
                    minHeight: "50vh",
                }}
                justifyContent={"center"}
                alignItems={"center"}
                justifyItems={"center"}
                justifySelf={"center"}
            >
                <Grid item> <Typography variant="h3" style={{color: 'white'}}>
                    {Strings.it.accessLimited}
                </Typography></Grid>

                <Grid item> <Button onClick={() => {
                    store.dispatch(navigateTo(NavigationPath.LANDING_PAGE))
                }} variant="contained">{Strings.it.formRegistrationResultBtn}</Button></Grid>

            </Grid>
        );
    } else return (
        <Grid
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: purple[800],
                minHeight: "50vh",
            }}
            justifyContent={"center"}
            alignItems={"center"}
            justifyItems={"center"}
            justifySelf={"center"}
        >
            <Grid item> <Typography variant="h3" sx={{color: 'white'}}>
                {registrationResultMessage}
            </Typography></Grid>
            {/* <Grid item> <Typography variant="h6" sx={{color: 'white'}}>
                {Strings.it.formRegistrationResultEmailLabel} {profileIds.email}
            </Typography></Grid> */}
            {registrationResultMessage.includes('aggiornato') == false ? 
                [   <Grid item>
                        <Typography variant="h6" sx={{color: 'white'}}>
                        {Strings.it.formRegistrationResultNotice}
                        </Typography></Grid>,

                    <Grid item>
                        <Typography sx={{color: "white"}}>{window.location.protocol}//{window.location.host}/census/profiles/{profileIds.uri}</Typography>
                    </Grid>,
                    
                ]: null
            }
            <Grid item>
                <Button onClick={() => {
                verifyToken(null,
                    ()=>{store.dispatch(navigateTo(NavigationPath.VALIDATOR_PANEL))},
                    ()=>{store.dispatch(navigateTo(NavigationPath.LANDING_PAGE))})
                }} variant="contained">{Strings.it.formRegistrationResultBtn}</Button>
            </Grid>
        </Grid>
    );
}
