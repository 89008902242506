import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { emailRegex, phoneRegex } from "../../../util/const";
import InputField from "../../commonComponents/inputField/InputField";
import {
    selectCurrentSection,
    selectInputPersonalInfo,
    setCurrentSection,
    setPersonalInformation
} from "../registrationSlice";
import React from "react";
import { registrationSectionEnum } from "../const";


const PersonalDataSection = () => {
    const inputPersonalInfo = { ...useSelector(selectInputPersonalInfo) }
    const cs = useSelector(selectCurrentSection)
    const dispatch = useDispatch()
    if (cs !== registrationSectionEnum.PERSONAL_DATA_SECTION) dispatch(setCurrentSection(registrationSectionEnum.PERSONAL_DATA_SECTION))

    const onFieldValueChange = (fieldType, value) => {
        if (fieldType === "ACTIVITY_NAME") inputPersonalInfo.activityName = value
        if (fieldType === "REFER_NAME") inputPersonalInfo.referName = value
        if (fieldType === "PHONE_NUMBER_REFER") inputPersonalInfo.phoneNumber = value
        if (fieldType === "EMAIL_REFER") inputPersonalInfo.email = value
        if (fieldType === "EMAIL_OPERATIVE") inputPersonalInfo.operativeEmail = value
        if (fieldType === "PHONE_NUMBER_OPERATIVE") inputPersonalInfo.operativePhoneNumber = value
        if (fieldType === "SITE_OPERATIVE") inputPersonalInfo.website = value


        dispatch(setPersonalInformation(inputPersonalInfo))
    }
    return (
        <Grid container justifyItems={"center"} alignContent={"center"} justifyContent={"center"} sx={{backgroundColor:"",paddingLeft:5}}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <Grid item xx={12} md={6} lg={6} xl={6}>
                        <InputField required={true}
                            label="Nome organizzazione" info="Nome Organizzazione" fieldType="ACTIVITY_NAME"
                            section="PERSONAL_DATA_REQUIRED" onValueChange={onFieldValueChange}
                            initialValue={inputPersonalInfo.activityName}/>
                </Grid>
                <Grid item sx={12} md={6} lg={6} xl={6}>
                        <InputField required={true} pattern={phoneRegex}
                            label="Telefono (contatto operativo)" info="Telefono (contatto operativo)" fieldType="PHONE_NUMBER_OPERATIVE"
                            section="PERSONAL_DATA_REQUIRED" onValueChange={onFieldValueChange}
                            initialValue={inputPersonalInfo.operativePhoneNumber} />
                </Grid>
               
                <Grid item sx={12} md={6} lg={6} xl={6}>
                        <InputField required={true} pattern={emailRegex}
                            label="Email operativa" info="email operativa" fieldType="EMAIL_OPERATIVE"
                            section="PERSONAL_DATA_REQUIRED" onValueChange={onFieldValueChange}
                            initialValue={inputPersonalInfo.operativeEmail} />
                </Grid>
                <Grid item sx={12} md={12} lg={12} xl={12}>
                        <InputField
                            label="Sito internet organizzazione" info="Sito internet dell'Organizzazione" fieldType="SITE_OPERATIVE"
                            onValueChange={onFieldValueChange}
                            initialValue={inputPersonalInfo.website} />
                </Grid>
                <Grid item xx={12} md={6} lg={6} xl={6}>
                        <InputField
                            label="Nome referente (Opzionale)" info="Nome referente" fieldType="REFER_NAME"
                            section="PERSONAL_DATA_REQUIRED" onValueChange={onFieldValueChange}
                            initialValue={inputPersonalInfo.referName} />
                </Grid>
                <Grid item sx={12} md={6} lg={6} xl={6}>
                        <InputField pattern={emailRegex}
                            label="Email referente" info="email del referente" fieldType="EMAIL"
                            section="PERSONAL_DATA_REQUIRED" onValueChange={onFieldValueChange}
                            initialValue={inputPersonalInfo.email} />
                </Grid>
                <Grid item sx={12} md={6} lg={6} xl={6}>
                        <InputField pattern={phoneRegex}
                            label="Telefono referente" info="Telefono referente" fieldType="PHONE_NUMBER_REFER"
                            section="PERSONAL_DATA_REQUIRED" onValueChange={onFieldValueChange}
                            initialValue={inputPersonalInfo.phoneNumber} />
                </Grid>
                
            </Grid>
        </Grid>
    );
};

export default PersonalDataSection;
