import { Box, Button } from "@mui/material"
import { useDispatch } from "react-redux"
import { Styles } from "../../design/styles"
import { navigateTo } from "../navigation/navigationSlice"
import {Strings} from "../../assets/Strings";

export const FooterItem = ({ item }) => {
    const dispatch = useDispatch()

    const handleOnClick = ()=>{dispatch(navigateTo(item.navigationPath))}
    return (
      <div >
        <Box>
        <Button {...Styles.FooterItem.Button.Props} onClick={handleOnClick}> {item.title}</Button> 
        </Box>
      </div>
    )
  }

  export const CopyRightSection = () => {
    return (
      <Box {...Styles.Footer.CopyRightSection.Props}>
          {Strings.it.copyright} &reg; {new Date().getFullYear()}
      </Box>
    )
  }
  