import {Divider, Grid, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";



export default function BranchDetailComponent({branch}) {
    const days = branch.schedule?.days ? branch.schedule?.days : dayDummyData()
    return (
        <div style={{backgroundColor: "",}}>
            <Grid container spacing={1} marginBottom={1}
                  direction="column"
                  alignItems="center"
                  justifyContent="center">
                <Grid item xs={12} md={12}>
                    <Card sx={{padding: "8px", backgroundColor:  "RGB(225,231,12,.1)"}} elevation={1}>
                        <Typography variant={""}>L'indirizzo: {!branch.address ? "non è stato fornito": branch.address}</Typography>
                    </Card>
                </Grid>
                <Grid container xs={12} md={12} lg={12}
                      alignItems="center"
                      justifyContent="center">
                    {days.map((item, index) => {
                        return (<DayDetailsComponent item={item} key={index}/>)
                    })}
                </Grid>
            </Grid>
        </div>
    )
}


export function DayDetailsComponent({item}) {
    return (
        <Card container
              sx={{
                  backgroundColor: item.isClosed || (item.morningClose && item.eveningClose) ? "RGB(225,125,25,.1)" : "RGB(125,195,25,.1)",
                  position: "relative",
                  marginRight: "auto",
                  marginLeft: "auto",
                  borderRadius: "5px",
                  maxWidth: "300px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  margin: "12px"
              }}
              elevation={1}
        >

            <CardContent>
                <Typography>{item.dayName}</Typography>
                <Divider/>
                {item.isClosed || (item.morningClose && item.eveningClose)
                    ? "chiuso"
                    : <div>
                        <Grid item xs={12} md={12}
                              sx={{paddingLeft: "",backgroundColor: item.morningClose? "RGB(225,125,25,.1)":""}}>Mattina:{item.morningClose? " chiuso": ` ${item.openingTimeMorning} - ${item.closingTimeMorning}`}</Grid>
                        <Grid item xs={12} md={12}
                              sx={{paddingLeft: "",backgroundColor: item.eveningClose? "RGB(225,125,25,.1)":""}}>Pomeriggio: {item.eveningClose? " chiuso": ` ${item.openingTimeEvening} - ${item.closingTimeEvening}`}</Grid>
                    </div>}

                <Grid item xs={12} md={12}>{item.isClosed}</Grid>
            </CardContent>
        </Card>
    )
}



function dayDummyData() {
    return [{
        dayName: "Wednesday",
        openingTimeMorning: "00:00",
        openingTimeEvening: "00:00",
        closingTimeMorning: "00:00",
        closingTimeEvening: "00:00",
        isClosed: false
    }, {
        dayName: "Wednesday",
        openingTimeMorning: "00:00",
        openingTimeEvening: "00:00",
        closingTimeMorning: "00:00",
        closingTimeEvening: "00:00",
        isClosed: false
    }, {
        dayName: "Wednesday",
        openingTimeMorning: "00:00",
        openingTimeEvening: "00:00",
        closingTimeMorning: "00:00",
        closingTimeEvening: "00:00",
        isClosed: false
    }, {
        dayName: "Wednesday",
        openingTimeMorning: "00:00",
        openingTimeEvening: "00:00",
        closingTimeMorning: "00:00",
        closingTimeEvening: "00:00",
        isClosed: false
    }, {
        dayName: "Wednesday",
        openingTimeMorning: "00:00",
        openingTimeEvening: "00:00",
        closingTimeMorning: "00:00",
        closingTimeEvening: "00:00",
        isClosed: false
    },
        {
            dayName: "Wednesday",
            openingTimeMorning: "00:00",
            openingTimeEvening: "00:00",
            closingTimeMorning: "00:00",
            closingTimeEvening: "00:00",
            isClosed: false
        },
        {
            dayName: "Wednesday",
            openingTimeMorning: "00:00",
            openingTimeEvening: "00:00",
            closingTimeMorning: "00:00",
            closingTimeEvening: "00:00",
            isClosed: false
        },
    ];
}  
