import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { NavigationPath } from '../features/navigation/navigationPath';
import { navigateTo } from '../features/navigation/navigationSlice';
import {createEmptyAccount, logout, setAccount, setAuthTokens, setLoginStatus} from '../features/validator/authSlice';
import { LoginStatus } from '../features/validator/loginStatus';
import { store } from '../store/store';
import { API } from './api';


const authorizedClient = axios.create({
    baseURL: API.baseUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});

authorizedClient.interceptors.request.use(async (config) => {
    const { token } = store.getState().auth;
    if (token !== null || token !== "") {
        config.headers.Authorization = `Bearer  ${token}`;
        //console.debug('[Request]', config.baseURL + config.url, JSON.stringify(token));
    } else {
        store.dispatch(logout())
        store.dispatch(setLoginStatus(LoginStatus.LOG_OUT))
        store.dispatch(navigateTo(NavigationPath.VALIDATOR_LOGIN))
        return Promise.reject();
    }
    return config;
});

authorizedClient.interceptors.response.use(
    (res) => {
        // @ts-ignore
        //console.debug('[Response]', res.config.baseURL + res.config.url, res.status, res.data);
        return Promise.resolve(res);
    },
    (err) => {
        //console.debug('[Response]',err.config.baseURL + err.config.url, err.response.status,err.response.data);
        return Promise.reject(err);
    }
);

const refreshAuthLogic = async (failedRequest) => {
    const { refreshToken } = store.getState().auth;
    if (refreshToken !== null && refreshToken !== "") {
        return axios
            .post(
                '/auth/refresh/',
                {
                    refresh: refreshToken,
                },
                {
                    baseURL: API.baseUrl
                }
            )
            .then((resp) => {
                const { access, refresh } = resp.data;
                failedRequest.response.config.headers.Authorization = `Bearer  ${access}`;
                store.dispatch(
                    setAuthTokens({ token: access, refreshToken: refresh })
                );
                if (resp.data !==null){
                    store.dispatch(setAccount(resp.data))

                } else {
                    store.dispatch(logout())
                    store.dispatch(setLoginStatus(LoginStatus.LOG_OUT))
                    store.dispatch(navigateTo(NavigationPath.VALIDATOR_LOGIN))
                    store.dispatch(setAccount(createEmptyAccount()));
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    store.dispatch(logout());
                }
            });
    }
};

createAuthRefreshInterceptor(authorizedClient, refreshAuthLogic);

export function fetcher(account) {
    if(account === null || account.id === -1) return null
    return authorizedClient.get(`/user/${account.id}/`).then((res) => res.data).catch((e)=>{})
}

const publicClient = axios.create({
    baseURL: API.baseUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});

export {authorizedClient,publicClient};