export const Strings = {
    it: {
        landingSheet: {
            title: "QUESTO E' IL DATABASE DELLA RETE DEL BENESSERE DALL'ALTA VAL DI CECINA E VALDERA",
            content: "Uno strumento totalmente a disposizione di chi opera nelle realtà del territorio che si occupano di servizi socio-sanitari socio-assistenziali e che potranno in autonomia sia caricare le informazioni relativamente ai servizi offerti dalla propria realtà, sia trovare tra i servizi erogati da altre realtà quelli che rispondono meglio ai bisogni dei propri utenti."
        },
        phone: "Telefono",
        operativePhoneNumber: "Telefono (contatto operativo)",
        search: "Cerca",
        searchForActivity: "CERCA UN SERVIZIO",
        descriptionNotProvided: "Non è stata fornita alcuna descrizione.",
        howToFillTheForm: `Questa app è una delle sperimentazioni sviluppate nell'ambito di Cantieri della Salute, progetto di Regione Toscana, coordinato da Federsanità - ANCI Toscana con il supporto tecnico di Sociolab cooperativa impresa sociale.
                          A seguito di un percorso di coinvolgimento e progettazione partecipata di alcune realtà del Terzo settore dei territori della Zona Distretto Alta Val di Cecina e Valdera, con la collabroazione dell'omonima Società della Salute e il supporto dell'Unine dei Comuni della Valdera, è stata rilasciata questa app che viene messa a disposizione delle realtà che aderiscono alla nascente Rete del Benessere della Valdera che si impegnano ad utilizzarla in via sperimentale nel corso dell'anno 2023.`,
        copyright: "Footer (testo da inserire)",
        morningOpens: "Mattina: apre",
        morningCloses: "Mattina: chiude",
        eveningOpens: "Pomeriggio: apre",
        eveningCloses: "Pomeriggio: apre",
        closed: "Chiuso",
        addYourKeyword: "Aggiungi la tua parola chiave",
        inAssociation: "Nell'associazione",
        waitingForValidation: "In attesa di convalida",
        waitingForResponse: "In attesa della risposta...",
        connectionProblem: "Problem connecting to server.",
        clickOnAField: "Clica su un campo",
        pleaseLoginAgain: "Si prega di riprovare il login",
        notFound: "Pagina non trovata",
        goHomePage: "Vai alla pagina iniziale",
        validate: "Valida",
        validateWithoutChange: "Valida Senza modificare",
        close: "Chiudi",
        appNameFirst: "UNIONE VALDERA",
        appNameSecond: "PORTALE DI CENSIMENTO",
        whoWeAre: "Chi Siamo",
        whoWeAreBody: `La rete del benessere della Valdera raccoglie le organizzazioni che hanno sede in Valdera e Alta Val di Cecina e che si riconoscono nei seguenti principi:
                      valore primario della persona e del suo benessere
                      importanza della partecipazione diretta dell'utente al servizio in quanto soggetto attivo non solo passivo fruitore
                      importanza della libertà di accesso e di parola
                      rifiuto di ogni tipo di discriminazione`,
        notices: "Avvisi",
        contacts: "Contatti",
        technicalAssistance: "Assistenza tecnica",
        noInformationProvided: "nessuna informazione trovata",
        doYouNeedHelp: "Serve aiuto?",
        loginButtonLabel: "Accedi",
        formRegistrationResultNotice: "Puoi controllare lo stato della tua registrazione tornando su questa pagina:",
        formRegistrationResultBtn: "Vai alla pagina iniziale",
        formRegistrationResultEmailLabel: "Hai utilizzato la email:",
        accessLimited: "Accesso limitato",
        days: ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"],
        months: [],
        info: "Informazione",
        logout: "Logout",
        contactUs: "Contattaci",
        explore: "Explore",
        register: "Register",
        registerActivity: "REGISTRA LA TUA ORGANIZZAZIONE",
        back: "Indietro",
        forward: "Avanti",
        save: "Salva",
        modifyAndSave: "Modifica e salva",
        statistics: "Statistiche",
        ourGoal: "Il nostro obiettivo",
        ourGoalBody: "Promuovere una società improntata all'uguaglianza e ai pari diritti per ciascuno indipendentemente da fragilità e vulnerabilità attraverso la promozione delle attività delle associazioni e delle organizzazioni che sul territorio si riconoscono nei principi di cui all’art.1.",
        forWhoWorksWithUs: "Per chi lavora con noi",
        reservedArea: "Area riservata",
        advancedSearch: {
            title: "Ricerca",
            activityName: "Nome organizzazione",
            address: "Indirizzo",
            keyword: "Parola Chiave",
            name: "Nome o Cognome"
        },
        registerYourData: "Registra i tuoi dati",
        homePage: "Pagina iniziale",
        generalInformation: "Informazioni generali",
        selectedField: "Campo selezionato",
        itIsObligatoryM: "è obbligatorio.",
        itIsObligatoryF: "è obbligatoria.",
        formRegistrationTitle: "Questionario di registrazione",
        toSmallToRender: "Width is too small to show the contents.",
        accepted: "Accettato",
        registrationLabels: {
            initial: "Nota bene",
            personalData: "Dati personali",
            businessActivity: "Dettaglio organizzazione",
            description: "Servizi offerti",
            schedule: "Sede e Orari",
            final: "Controlla i dati",
        },
        loading: "Caricamento",
        beforeYouBegin: "Prima di iniziare",
        beforeYouBeginDesc: "Si dichiara la presa visione e accettazione",
        PrivacyPolicyPopup0: `
            ai fini previsti dal Regolamento UE 2016/679 sopra richiamato (di seguito “G.D.P.R. 2016/679”),
            recante le nuove disposizioni a tutela della “privacy” e relativo alla protezione e al trattamento dei dati
            personali, si informa che il trattamento dei dati personali che da Lei saranno forniti in futuro in
            qualità di “interessato”, saranno oggetto di trattamento nel rispetto della normativa prevista dal citato
            Regolamento e degli obblighi di riservatezza, correttezza, liceità e trasparenza.`,
        PrivacyPolicyPopup1: `
            Titolare del trattamento è la Società della Salute Alta Val di Cecina Valdera, Via Fantozzi n. 14 56025
            Pontedera (Pi) nella persona del suo legale rappresentante pro tempore (Email:
            staffsdsvaldera@uslnordovest.toscana.it PEC: direzione.uslnordovest@postacert.toscana.it ,
            centralino +39 0587 273111).`,
        PrivacyPolicyPopup2: `
            I dati personali forniti sono trattati dalla Società della Salute Alta Val di Cecina Valdera per le finalità
            previste dal Regolamento (UE) 2016/679 (RGPD), in particolare per l’esecuzione di un compito di
            interesse pubblico (art. 6, par. 1, lett. e) e per il perseguimento del legittimo interesse del Titolare (art.
            6, par. 1, lett. f) relativamente alle attività connesse al percorso partecipativo di Regione Toscana
            Cantieri della Salute di cui protagonista attivo è il Comitato di Partecipazione della Società della Salute
            Alta Val di Cecina Valdera (art. 16 quater LR 40/2005 Disciplina del servizio sanitario regionale)
            `,
        PrivacyPolicyPopup3: `
            I dati personali raccolti sono trattati dal personale della Società della Salute Alta Val di Cecina
            Valdera, che agisce sulla base di specifiche istruzioni fornite in ordine a finalità e modalità del
            trattamento medesimo.
            Ferme restando le comunicazioni eseguite in adempimento di obblighi di legge, tutti i dati raccolti ed
            elaborati potranno essere comunicati per le finalità di cui sopra a:
            a) soggetti incaricati dalla Società della Salute Alta Val di Cecina Valdera dell’esecuzione di attività
            direttamente connesse o strumentali alle attività specifiche del progetto Cantieri della Salute e del Comitato di Partecipazione.
            I dati personali non saranno trasferiti in un Paese extra Europeo né saranno oggetto di processi
            decisionali automatizzati compresa la profilazione
            `,
        PrivacyPolicyPopup4: `
            Gli interessati hanno il diritto di ottenere dalla Società della Salute Alta Val di Cecina Valdera, nei casi
            previsti, l’accesso ai dati personali e la rettifica o la cancellazione degli stessi, la limitazione del
            trattamento che li riguarda o di opporsi al trattamento scrivendo all&#39;indirizzo
            privacy.sdsavcvde@uslnordovest.toscana.it oppure utilizzando l&#39;apposito  Modello per l’esercizio
            dei diritti in materia di protezione dei dati personali scaricabile dalla sezione Privacy del sito web
            della Società della Salute Alta Val di Cecina Valdera, da inviare via pec all’indirizzo
            direzione.uslnordovest@postacert.toscana.it .
            `,
        PrivacyPolicyPopup5: `
        I dati saranno trattati per tutto il tempo necessario all’esecuzione delle attività previste dal progetto
cantieri della Salute e successivamente i dati saranno conservati in conformità alle norme sulla
conservazione della documentazione amministrativa. Il Titolare si riserva il diritto di modificare o
integrare la presente informativa in qualsiasi momento. Per ogni aggiornamento gli Interessati sono
invitati a consultare il sito web istituzionale e a prendere visione delle eventuali variazioni apportate.
        `,
        PrivacyPolicyPopup6: `
        La informiamo, inoltre, che i dati raccolti non saranno mai diffusi e non saranno oggetto di
comunicazione senza il Suo esplicito consenso, salvo le comunicazioni necessarie che possono
comportare il trasferimento di dati personali (CITARE I DATI PERSONALI CHE SI
RICHIEDONO PER LA APP ) ai componenti della rete di governance del progetto Cantieri della
Salute al fine di agevolare la funzionalità e la piena operatività del progetto stesso e a soggetti terzi,
incaricati dalla Società della Salute Alta Val di Cecina Valdera dell’esecuzione di attività direttamente
connesse o strumentali alle attività specifiche del progetto Cantieri della Salute e del Comitato di
Partecipazione Inoltre tali dati potranno essere conosciuti, nei limiti della normativa vigente da
dipendenti e/o collaboratori espressamente nominati ed incaricati dal Titolare.
        `,
        PrivacyPolicyPopup7: `
        Poiché il trattamento è necessario per l’esecuzione di un compito di interesse pubblico (art. 6, par. 1,
            lett. e), il conferimento è obbligatorio e la mancata comunicazione dei dati comporta l’impossibilità di
            accedere ai contenuti della app del progetto Cantieri della Salute (art. 13, par. 2, lett. e del GDPR).
        `,
    }
}
