import {Grid} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import BranchListComponent from "../../healthcareProviderProfile/BranchListComponent";
import PersonalDataComponent from "../../healthcareProviderProfile/PersonalDataComponent";
import {selectCurrentSection, selectHealthcareWorkerData, setCurrentSection} from "../registrationSlice";
import ConsentComponent from "../privacy/ConsentComponent";
import {consentItems} from "../privacy/consentItems";
import {registrationSectionEnum} from "../const";
import {NavigationPath} from "../../navigation/navigationPath";
import {selectNavigation} from "../../navigation/navigationSlice";

export default function FinalControl({profile, editable = true, isValidator = false}) {
    const resProfile = useSelector(selectHealthcareWorkerData)
    if (!profile) profile = resProfile
    const cs = useSelector(selectCurrentSection)
    const cp = useSelector(selectNavigation)
    const dispatch = useDispatch()

    if (isValidator && editable && cs !== registrationSectionEnum.FINAL_CHECK) dispatch(setCurrentSection(registrationSectionEnum.FINAL_CHECK))
    return (
        <Grid
            container justifyContent={"center"} alignItems={"center"}
        >
            <Grid sx={{backgroundColor: ""}} item xs={12} md={12}><PersonalDataComponent profile={profile || {}}
                                                                                         editable={false}
                                                                                         isValidator={isValidator}/></Grid>
            <Grid sx={{backgroundColor: ""}} item xs={10} md={10}><BranchListComponent branches={profile.Branches || []}
                                                                                       editable={false}/></Grid>
            {/* {editable && ([NavigationPath.EDIT_PROFILE, NavigationPath.FORM_REGISTRATION,17].includes(cp)) ?
                <Grid sx={{backgroundColor: ""}} item xs={10} md={10}> {consentItems.map((item) => {
                    return <Grid item xs={12} md={12}><ConsentComponent item={item} key={item.id}
                                                                        editable={editable}/></Grid>
                })}</Grid>
                : <></>} */}
        </Grid>
    )
}