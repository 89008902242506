import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import {selectFieldErrors, selectHideErrorCamp} from "../../errorStore/errorStoreSlice";
import {selectErrorInformation} from "./informationPanelSlice";
import {Styles} from "../../../design/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from '@fortawesome/fontawesome-free-solid'

const InformationPanel = () => {
    const errorInformation = useSelector(selectErrorInformation)
    const errors = useSelector(selectFieldErrors)
    const hide = useSelector(selectHideErrorCamp)
    return (
        hide || (errors.length === 0) ? <></> :
            <Box sx={Styles.InformationPanel.Root}>
                <Box sx={{fontWeight: "400"}}><p style={{color: "RGB(3,3,3,1)"}}>{errorInformation.title}</p></Box>
                <Box sx={{fontWeight: "300"}}><p style={{color: "RGB(213,53,13,1)"}}>{errorInformation.body}</p></Box>
                <br/>
                <div style={{overflow: "auto", maxHeight: "120px"}}>
                    {
                        errors.map((item) => {
                            return <Box color={"red"} sx={{
                            fontWeight: "300",
                            color: "red"
                        }}>
                            <FontAwesomeIcon icon={faExclamation}
                                             style={{paddingRight: "10px", position: "inline"}}/>
                            {item.fieldName}: {item.body}
                        </Box>})
                    }
                </div>
                <br/>
            </Box>);
};

function ErrorBox({item}) {
    return (
        <Box sx={{fontWeight: "400", position: "inline", color: "red"}}>
            <FontAwesomeIcon icon={faExclamation} style={{paddingRight: "10px", position: "inline"}}/>
            {item.fieldName}: {item.body}
        </Box>
    );
}

export default InformationPanel;
