import {
    isEmailAlreadyRegistered,
    handleRegisterHealthcareWorkerData,
    handleUpdateHealthcareWorkerData
} from "../../networkEngine/functions"
import { store } from "../../store/store"

import {
    setApiConnectionStatus,
    setApiConnectionStatusDetails
} from "../apiConnectionIndicator/apiConnectionIndicatorSlice"
import {setAllKeywords} from "../commonComponents/richTextField/richTextFieldSlice"
import {addFieldErrors, removeFieldErrors} from "../errorStore/errorStoreSlice"
import {NavigationPath} from "../navigation/navigationPath"
import {setBranches} from "./branchInformation/branchInformationSlice"
import {updateBranchInformationByValue} from "./branchInformation/functions"
import {getActivityData} from "./businessDataSection/businessDataSectionSlice"
import {ApiConnectionStatus, ERROR_TYPE, registrationSectionEnum} from "./const"
import {fetchSuggestedKeyword} from "./DescriptionDataSection/suggestedKeywordsSlice"
import {
    setBusinessActivity,
    setCurrentSection,
    setDescription,
    setHealthcareWorkerData,
    setPersonalInformation, setValidationStatus
} from "./registrationSlice"
import {Strings} from "../../assets/Strings";
import {addNewTab, removeTabByPosition} from "./schedulerTab/scheduleSectionSlice";
import {associationPartnership, emailRegex, phoneRegex, webRegex} from "../../util/const";


// PERSONAL INFORMATION SECTION
export function getPersonalDataEmptyObject() {
    return {
        activityName: "",
        referName: "",
        phoneNumber: "",
        operativePhoneNumber: "",
        email: "",
        operativeEmail: "",
        website: "",
        associationPartnership: associationPartnership.SI
    }
}
export function checkPersonalDataForError(personalData, dispatch) {
    if (personalData.activityName === "")
        dispatch(addFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.ACTIVITY_NAME));
    else
        dispatch(removeFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.ACTIVITY_NAME));

    if (personalData.operativePhoneNumber === "")
        dispatch(addFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.PHONE_NUMBER_OPERATIVE));
    else
        dispatch(removeFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.PHONE_NUMBER_OPERATIVE));

    if (personalData.operativeEmail === "")
        dispatch(addFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.OPERATIVE_EMAIL_OBLIGATORY));
    else
        dispatch(removeFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.OPERATIVE_EMAIL_OBLIGATORY));

    // CHECK FORMATS
    if (personalData.operativeEmail !== "" && !emailRegex.test(personalData.operativeEmail))
        dispatch(addFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.EMAIL));
    else if(personalData.operativeEmail !== "")
        dispatch(removeFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.EMAIL));

    if (personalData.email !== "" && !emailRegex.test(personalData.email))
        dispatch(addFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.EMAIL));
    else if(personalData.email !== "")
        dispatch(removeFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.EMAIL));

    if (personalData.phoneNumber !== "" && !phoneRegex.test(personalData.phoneNumber))
        dispatch(addFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.PHONE_NUMBER_REF));
    else if(personalData.phoneNumber !== "")
        dispatch(removeFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.PHONE_NUMBER_REF));
    if (personalData.operativePhoneNumber !== "" && !phoneRegex.test(personalData.operativePhoneNumber))
        dispatch(addFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.PHONE_NUMBER_OP));
    else if(personalData.operativePhoneNumber !== "")
        dispatch(removeFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.PHONE_NUMBER_OP));
    /*
    if (personalData.website !== "" && !webRegex.test(personalData.website))
        dispatch(addFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.WEB_SITE));
    else
        dispatch(removeFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.WEB_SITE));
    */

}
export async function handleExitPersonalDataSection(personalData, dispatch) {
    checkPersonalDataForError(personalData, dispatch);
    const fieldErrors = store.getState().errorStore.fieldErrors;
    if (fieldErrors.length === 0) {
        dispatch(setApiConnectionStatus(ApiConnectionStatus.LOADING));
        const alreadyExist = await isEmailAlreadyRegistered(personalData)
        if (alreadyExist === true) {
            dispatch(addFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.ALREADY_USED))
        } else if (alreadyExist === null) {
            //
        } else {
            dispatch(removeFieldErrors(ERROR_TYPE.PERSONAL_DATA_REQUIRED.ALREADY_USED))
            store.dispatch(getActivityData())
            dispatch(setCurrentSection(registrationSectionEnum.BUSINESS_ACTIVITY_SECTION));
            dispatch(setApiConnectionStatus(ApiConnectionStatus.IDLE));
        }
    }
}
///////////////////////////////
// BUSINESS SECTION
export function handleExitBusinessActivitySection(activities, dispatch) {
    if (activities.length < 1 || activities.length > 5)
        dispatch(addFieldErrors(ERROR_TYPE.REQUIRED_ACTIVITIES_NUMBER));
    else {
        dispatch(removeFieldErrors(ERROR_TYPE.REQUIRED_ACTIVITIES_NUMBER));
        dispatch(fetchSuggestedKeyword(true));
        dispatch(setCurrentSection(registrationSectionEnum.BUSINESS_DESCRIPTION_SECTION));
    }

}
///////////////////////////////
// BRANCH SECTION
export function handleExitBranchSection(dispatch, branches) {
    return validateFormData()
}
///////////////////////////////

export function validateFormData() {
    let isValid = false
    const state = store.getState()
    const fieldErrors = state.errorStore.fieldErrors;
    isValid = (fieldErrors.length === 0)
    if (!isValid) return false

    let custom_id = "-"
    if (state.validatorSection.currentEntryToExpand && state.validatorSection.currentEntryToExpand.custom_id) {
        custom_id = state.validatorSection.currentEntryToExpand.custom_id
    }

    const formData = state.registrationForm
    const branches = state.branchInformation.branches
    const keywords = Array.from(new Set(state.richTextField.keywords))
    const validationStatus = state.registrationForm.validationStatus
    const personalData = { ...formData.inputPersonalInfo }
    const associationPartnership = state.registrationForm.doParticipate
    const healthcareWorkerData = getHealthcareWorkerJSON(
        personalData, formData.inputBusinessActivity,
        formData.inputDescription, branches, keywords, validationStatus, custom_id, associationPartnership
    )
    store.dispatch(setHealthcareWorkerData(healthcareWorkerData))

    return isValid
}

export function postRegistrationData() {
    if ([NavigationPath.EDIT_PROFILE, 17].includes(store.getState().navigation.value)) {
        handleUpdateHealthcareWorkerData()
    } else if (store.getState().navigation.value === NavigationPath.FORM_REGISTRATION) {
        handleRegisterHealthcareWorkerData()
    } else {
        store.dispatch(setApiConnectionStatus(ApiConnectionStatus.FAILED))
    }
}

//Final one
export function getHealthcareWorkerJSON(personalData, businessData, descriptionData, scheduleData, keywords, validationStatus, custom_id, associationPartnership) {
    return {
        ...personalData,
        activities: [...businessData],
        keywords: keywords.map((kw) => {
            return {word: kw}
        }),
        description: descriptionData,
        Branches: scheduleData,
        validationStatus: validationStatus,
        associationPartnership: associationPartnership,
        custom_id: custom_id
    }
}

export function getHealthcareWorkerEmptyObject() {
    return {
        personalData: getPersonalDataEmptyObject(),
        businessData: {},
        descriptionData: {},
        scheduleData: {},
    }
}


export function handleSubmitForm(dispatch) {
    if (validateFormData()) {
        dispatch(setApiConnectionStatusDetails(Strings.it.waitingForResponse));
        dispatch(setApiConnectionStatus(ApiConnectionStatus.LOADING));
        postRegistrationData();
    }
}


export function handleProfileEditRequest(profile) {
    store.dispatch(setCurrentSection(1));
    store.dispatch(setPersonalInformation(
        {
            activityName: profile.activityName,
            referName: profile.referName,
            email: profile.email,
            operativeEmail: profile.operativeEmail,
            phoneNumber: profile.phoneNumber,
            operativePhoneNumber: profile.operativePhoneNumber,
            website: profile.website,
            associationPartnership: profile.associationPartnership,
            custom_id: profile.custom_id
        }
    ));
    store.dispatch(setValidationStatus(profile.validationStatus))
    store.dispatch(setBusinessActivity(profile.activities));
    store.dispatch(setDescription(profile.description));
    store.dispatch(setAllKeywords(profile.keywords.map((k) => {
        return k.word;
    })));
    const branches = profile.Branches;
    store.dispatch(removeTabByPosition(1))
    branches.forEach((branch) => {
        store.dispatch(addNewTab(branch.address ? branch.address : "Sede"))
    })
    const mappedBranches = branches.map((b) => {
        return updateBranchInformationByValue(b);
    })
    store.dispatch(setBranches(mappedBranches));
}

