import BusinessDataSection from "./businessDataSection/BusinessDataSection";
import DescriptionSection from "./DescriptionDataSection/DescriptionSection";
import PersonalDataSection from "./personalDataSection/PersonalDataSection";
import FinalControl from "./result/FinalControl";
import ScheduleSection from "./schedulerTab/ScheduleSection";
import InitialRegistrationInformation from "./initialInformationSection/InititialRegistrationInformation";
import * as icons from "@fortawesome/free-solid-svg-icons";
import {Strings} from "../../assets/Strings";
export const registrationSectionEnum = {
    INITIAL_INFORMATION_SECTION:0,
    PERSONAL_DATA_SECTION: 1,
    BUSINESS_ACTIVITY_SECTION: 2,
    BUSINESS_DESCRIPTION_SECTION: 3,
    BUSINESS_SCHEDULE_SECTION: 4,
    FINAL_CHECK:5
};

export const RegistrationSections = [
    {
        name: registrationSectionEnum.INITIAL_INFORMATION_SECTION,
        label: Strings.it.registrationLabels.initial,
        component: <InitialRegistrationInformation />,
        info: "",
        icon: icons.faCircleInfo
    },
    {
        name: registrationSectionEnum.PERSONAL_DATA_SECTION,
        label: Strings.it.registrationLabels.personalData,
        component: <PersonalDataSection />,
        info: "",
        icon: icons.faPerson
    },
    {
        name: registrationSectionEnum.BUSINESS_ACTIVITY_SECTION,
        label: Strings.it.registrationLabels.businessActivity,
        component: <BusinessDataSection />,
        info: "",
        icon: icons.faCodeBranch
    },
    {
        name: registrationSectionEnum.BUSINESS_DESCRIPTION_SECTION,
        label: Strings.it.registrationLabels.description,
        component: <DescriptionSection />,
        info: "",
        icon: icons.faBookReader
    },
    {
        name: registrationSectionEnum.BUSINESS_SCHEDULE_SECTION,
        label: Strings.it.registrationLabels.schedule,
        component: <ScheduleSection />,
        info: "",
        icon: icons.faBusinessTime
    },
    {
        name: registrationSectionEnum.FINAL_CHECK,
        label: Strings.it.registrationLabels.final,
        component: <FinalControl isValidator={true} editable={true} />,
        info: "",
        icon: icons.faCheck
    },
]

export const ApiConnectionStatus = {
    LOADING: 1,
    FAILED: 2,
    IDLE: 3,
    SUCCEED: 4,
    LOGIN: 5,
}
export const ERRORS = {
    NONE: {
        type: "",
        title: "",
        body: ""
    },
    EMAIL_ALREADY_EXIST: {
        type: "EMAIL_ALREADY_EXIST",
        title: "Errore di registrazione",
        body: "La email é già in uso."
    },
    REQUIRED_ACTIVITIES_NUMBER: {
        type: "REQUIRED_ACTIVITIES_NUMBER",
        title: "Errore di registrazione",
        body: "Bisogna selezionare almeno un'attività"
    }
}

export const ERROR_TYPE = {
    REQUIRED_ACTIVITIES_NUMBER: {
        fieldName: "Le Attività",
        body: "Bisogna selezionare almeno un'attività."
    },
    PERSONAL_DATA_REQUIRED: {
        ACTIVITY_NAME: {
            fieldName: "Nome organizzazione",
            body:"Il nome é obbligatorio."
        },
        PHONE_NUMBER_OPERATIVE: {
            fieldName: "Telefono (contatto operativo)",
            body:"Il numero di telefono é obbligatorio."
        },
        OPERATIVE_EMAIL_OBLIGATORY: {
            fieldName: "Email operativa",
            body:"La email é obbligatoria."
        },
        EMAIL: {
            fieldName: "Email",
            body:"Il formato della email non é corretto."
        },
        PHONE_NUMBER_OP: {
            fieldName: "Numero di telefono",
            body:"Il numero di telefono devo contenere solo caratteri numerici."
        },
        PHONE_NUMBER_REF: {
            fieldName: "Numero di telefono",
            body:"Il numero di telefono devo contenere solo caratteri numerici."
        },
        WEB_SITE: {
            fieldName: "Sito",
            body:"Il formato dell'indrizzo non é corretto."
        },
        PHONE_NUMBER_REFER: {
            fieldName: "Numero di telefono",
            body:"Il numero di telefono devo contenere solo caratteri numerici."
        },
        PHONE_NUMBER_OBLIGATORY: {
            fieldName: "Numero di telefono",
            body:"Il numero di telefono é obbligatorio."
        },
        EMAIL_OBLIGATORY: {
            fieldName: "Email",
            body:"La email é obbligatoria."
        },

        EMAIL_OPERATIVE: {
            fieldName: "Email",
            body:"Il formato della email non é corretto."
        },
        ALREADY_USED: {
            fieldName: "Email",
            body:"La email é già in uso."
        }
    },
    BRANCH_DATA_REQUIRED:{
        ADDRESS:{
            fieldName:"Indirizzo",
            body:"Il formato dell'indirizzo non é corretto."
        },
    }
}