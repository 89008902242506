import { createSlice } from "@reduxjs/toolkit";

const privacyDialogSlice = createSlice({
    name: 'privacyDialog',
    initialState: { value: false },
    reducers: {
        privacyToggleActive: (state) => {
            state.value = !state.value
        },
        privacyClose: (state)=>{
            state.value = false
        }
    }
})

export default privacyDialogSlice.reducer
export const selectPrivacyDialog = (state) => state.privacyDialog.value;
export const { privacyToggleActive,privacyClose } = privacyDialogSlice.actions