import {useSelector} from "react-redux";
import {selectAllActivities} from "../../registrationForm/businessDataSection/businessDataSectionSlice";
import {selectToken} from "../../validator/authSlice";
import {useState} from "react";
import {searchValidatorProfiles} from "../functions";
import {Button, Grid, Typography} from "@mui/material";
import {Strings} from "../../../assets/Strings";
import InputField from "../../commonComponents/inputField/InputField";
import DropdownMenuComponent from "../DropdownMenuComponent";
import {
    selectSearchValidatorCurrentPage,
    selectSearchValidatorNextPageUrl,
    setSearchParams
} from "./validatorSearchResultSlice";
import {store} from "../../../store/store";

export default function ValidatorSearchFilter() {
    const activities = useSelector(selectAllActivities)
    const token = useSelector(selectToken)
    const [activity, setActivity] = useState("")
    const [name, setName] = useState("")
    const [activityName, setActivityName] = useState("")
    const [address, setAddress] = useState("")
    const [keyword, setKeyword] = useState("")

    const handleComposeOnChange = (f, v) => {
        if (f === "NAME") setName(v)
        if (f === "ADDRESS") setAddress(v)
        if (f === "KEYWORD") setKeyword(v)
        if (f === "ACTIVITY_NAME") setActivityName(v)
    }

    const handleOnSubmit = () => {
        const searchParams = {
            activity: activity,
            keyword: keyword,
            name: name,
            activityName: activityName,
            address: address
        }
        store.dispatch(setSearchParams(searchParams));
        searchValidatorProfiles(token, searchParams);
    }

    return renderUI(handleComposeOnChange, keyword, activities, setActivity, handleOnSubmit)
}

function renderUI(handleComposeOnChange, keyword, activities, setActivity, handleOnSubmit) {
    return (
        <Grid item xs={12} md={12} xl={12} sx={{backgroundColor: "", position: "relative"}}>
            <Grid container>
                <Grid item xs={12} md={12} sx={{paddingLeft: "32px"}}><Typography
                    variant="h5">{Strings.it.advancedSearch.title}</Typography></Grid>
                <div style={{
                    display: "box",
                    width: "4px",
                    height: "30px",
                    backgroundColor: "black",
                    position: "absolute",
                    zIndex: "2",
                    left: "20px",
                    top: "0"
                }}></div>
                <Grid container alignItems={"center"} justifyContent={"center"} justifyItems={"center"}>
                    <Grid item xs={12}>
                        <Grid container alignItems={"center"} justifyContent={"center"} justifyItems={"center"}
                              justifySelf={"center"}>
                            <InputField label={Strings.it.advancedSearch.activityName} fieldType="ACTIVITY_NAME"
                                        onValueChange={handleComposeOnChange}></InputField>
                            <InputField label={Strings.it.advancedSearch.address} fieldType="ADDRESS"
                                        onValueChange={handleComposeOnChange}></InputField>
                            <InputField label={Strings.it.advancedSearch.keyword} fieldType="KEYWORD"
                                        onValueChange={handleComposeOnChange}
                                        initialValue={keyword}></InputField>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container sx={{backgroundColor: ""}} alignItems={"center"} justifyContent={"center"}
                              justifyItems={"center"} justifySelf={""}>
                            <InputField label={Strings.it.advancedSearch.name}
                                        fieldType="NAME"
                                        onValueChange={handleComposeOnChange}></InputField>
                            <DropdownMenuComponent
                                items={activities ? activities : []} onSelect={(item) => {
                                if (item !== null) {
                                    setActivity(item.name)
                                } else setActivity(null)
                            }}/>
                            <Button sx={{marginLeft: "8px", marginTop: ""}}
                                    onClick={() => {
                                        handleOnSubmit()
                                    }} variant="contained">{Strings.it.search}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={5} md={5} lg={5} sx={{marginTop: "32px", paddingLeft: ""}}></Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}