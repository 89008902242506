import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectBranches } from "./branchInformation/branchInformationSlice";
import {ERROR_TYPE, registrationSectionEnum} from "./const";
import { handleExitBusinessActivitySection, handleExitPersonalDataSection, handleExitBranchSection, handleSubmitForm } from "./functions";
import {
    selectCurrentSection, selectDoConsent,
    selectDoReadInitialInformation,
    selectInputBusinessActivity,
    selectInputPersonalInfo,
    setCurrentSection
} from "./registrationSlice";
import {Strings} from "../../assets/Strings";
import {store} from "../../store/store";
import {removeFieldErrors, setHideErrorCamp,resetAllErrors} from "../errorStore/errorStoreSlice";
import {getActivityData} from "./businessDataSection/businessDataSectionSlice";
import {selectCurrentEntryToExpand} from "../validator/validatorSectionSlice";

const FormController = () => {
    const currentSection = useSelector(selectCurrentSection)
    const personalData = useSelector(selectInputPersonalInfo)
    const activities = useSelector(selectInputBusinessActivity)
    const branches = useSelector(selectBranches)
    const doReadInitialInfo = useSelector(selectDoReadInitialInformation)
    const doConsent = useSelector(selectDoConsent)
    const dispatch = useDispatch()
    const cete = useSelector(selectCurrentEntryToExpand)
    const handleNextButtonDisability= ()=>{
        if(currentSection===registrationSectionEnum.INITIAL_INFORMATION_SECTION) return !doReadInitialInfo
        //if (currentSection===registrationSectionEnum.FINAL_CHECK) return doConsent !== "SI";
        return false
    };
    const handleBackStepClicked = () => {
        if (currentSection > 0) dispatch(setCurrentSection(currentSection - 1))
        if (currentSection === registrationSectionEnum.BUSINESS_DESCRIPTION_SECTION) {
            store.dispatch(getActivityData())
        }
        if (currentSection === registrationSectionEnum.BUSINESS_ACTIVITY_SECTION){
            dispatch(removeFieldErrors(ERROR_TYPE.REQUIRED_ACTIVITIES_NUMBER))
        }
    };
    const onGoToNextSection = async () => {
	dispatch(setHideErrorCamp(true))
        dispatch(resetAllErrors())
        switch (currentSection) {
            case registrationSectionEnum.INITIAL_INFORMATION_SECTION:
                if(store.getState().registrationForm.doReadInitialInformation){
                    store.dispatch(dispatch(setCurrentSection(registrationSectionEnum.PERSONAL_DATA_SECTION)));
                }
                break;
            case registrationSectionEnum.PERSONAL_DATA_SECTION:
                dispatch(setHideErrorCamp(false))
                await handleExitPersonalDataSection(personalData, dispatch);
                break;
            case registrationSectionEnum.BUSINESS_ACTIVITY_SECTION:
                dispatch(setHideErrorCamp(false))
                handleExitBusinessActivitySection(activities, dispatch);
                break;
            case registrationSectionEnum.BUSINESS_DESCRIPTION_SECTION:
                dispatch(setCurrentSection(registrationSectionEnum.BUSINESS_SCHEDULE_SECTION))
                break;
            case registrationSectionEnum.BUSINESS_SCHEDULE_SECTION:
                if (handleExitBranchSection(dispatch, branches)) {
                    dispatch(setCurrentSection(registrationSectionEnum.FINAL_CHECK))
                }
                break;
            case registrationSectionEnum.FINAL_CHECK:
                handleSubmitForm(dispatch);
                break;
            default:
                break;
        }
    };

    return _renderElement(handleBackStepClicked, currentSection, handleNextButtonDisability, onGoToNextSection,cete);
};

function _renderElement(handleBackStepClicked, currentSection, handleNextButtonDisability, onGoToNextSection,cete) {
    return (
        <div style={{position: "", bottom: 0, padding: "16px"}}>
            <br/>
            <Button
                variant="back"
                color="primary"
                onClick={handleBackStepClicked}
                hidden={currentSection === 0}
                disabled={currentSection === 0}
            >
                {Strings.it.back}
            </Button>
            <Button
                sx={{type: "submit"}}
                disabled={handleNextButtonDisability()}
                variant="contained"
                color="primary"
                onClick={onGoToNextSection}>
                {
                    currentSection === registrationSectionEnum.FINAL_CHECK
                        ?  (cete? Strings.it.modifyAndSave: Strings.it.save) : Strings.it.forward
                }
            </Button>
        </div>
    );
}

export default FormController;

