import {ACTION_SEARCH_GET_PUBLIC_DATA} from "./ActionType";
import {searchValidatorProfiles} from "../functions";

const publicSearchResultMiddleware = (store) => (next) => (action) => {
    if (action.type === ACTION_SEARCH_GET_PUBLIC_DATA) {
        searchValidatorProfiles(store.getState().auth.token,store.getState().publicSearchResult.searchParams,action.payload)
    }
    next(action)
}


export default publicSearchResultMiddleware;




